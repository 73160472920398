export enum MediaType {
  Video = "Video",
  Audio = "Audio",
}

export enum TranscodeStatus {
  Awaiting = "Awaiting",
  Complete = "Complete",
  Processing = "Processing",
  Error = "Error",
}

export enum OutputGroupType {
  HLS = "HLS",
  MPD = "MPD",
  FILE = "File",
}

export interface MediaFile {
  width: number;
  height: number;
  durationInMs: number;
  s3ObjectSize: number;
  objectUrls: string[];
  outputGroupType: string;
}

export interface MediaMetadata {
  id: string;
  visible: boolean;
  sortOrder: number;
  selectedThumbnail: number;
  mediaInfoId: string;
  profileId: string;
  mediaType: MediaType;
  title: string;
  description: string;
}

export interface MediaObject {
  ownerId: string;
  transcodeStatus: TranscodeStatus;
  durationInMs: number;
  outputFiles: MediaFile[];
  thumbnails: string[];
  id: string;
  originalFileName: string;
  s3ObjectKey: string;
  size: number;
  mimeType: string;
  mediaType: MediaType;
  created: Date;
  modified: Date;
}

export interface ViewMedia {
  media: MediaMetadata[];
  artistName: string;
}

export interface IAudioModel {
  id: string;
  duration: number;
  fileName: string;
  src: string;
}

export interface IVideoModel {
  duration: number;
  fileName: string;
  sources: string[];
  id: string;
  previewImg: string;
  downloadLink: string;
  shareLink: string;
  poster: string;
}

export interface IExternalMedia {
  id: string;
  sortOrder: number;
  mediaType: MediaType;
  title: string;
  profileId: string;
  durationInMs: number;
  url: string;
  thumbnail: string;
  isMediaObjectLoading?: boolean;
}

export type MediaModel = IVideoModel | IAudioModel;
