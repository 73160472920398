import { ReactComponent as NotesDocIcon } from "component-library/src/images/svg/icons/doc.svg";
import { ReactComponent as ProfileDocIcon } from "component-library/src/images/svg/icons/profiledoc.svg";
import { ReactComponent as PhoneMobileIcon } from "component-library/src/images/svg/icons/phone-mobile.svg";
import { ReactComponent as AudienceIcon } from "component-library/src/images/svg/icons/audience.svg";
import { ReactComponent as TicketsIcon } from "component-library/src/images/svg/icons/ticket.svg";
import { ReactComponent as UserOutlineIcon } from "component-library/src/images/svg/icons/user-outline.svg";
import { ReactComponent as ClapperBoardIcon } from "component-library/src/images/svg/icons/clapperboard.svg";
import { ReactComponent as PhoneLandlineIcon } from "component-library/src/images/svg/icons/phone-landline.svg";
import { ReactComponent as StarIcon } from "component-library/src/images/svg/icons/star.svg";

export enum SubscriptionCodes {
  Annual_DD = "performer_classic_annual_dd",
  Annual_CC = "performer_classic_annual_cc",
  Monthly_DD = "performer_classic_monthly_dd",
  Monthly_CC = "performer_classic_monthly_cc",
  YP_Annual_DD = "yp_classic_annual_dd",
  YP_Annual_CC = "yp_classic_annual_cc",
  YP_Monthly_DD = "yp_classic_monthly_dd",
  Graduate_Annual_DD = "graduate_classic_annual_dd",
  Graduate_Annual_CC = "graduate_classic_annual_cc",
  Graduate_Monthly_DD = "grad_classic_monthly_dd",
}

export const BASE_PERKS = [
  {
    name: "common:performer.planSelector.perks.roles.label",
    icon: NotesDocIcon,
  },
  {
    name: "common:performer.planSelector.perks.profile.label",
    icon: ProfileDocIcon,
  },
  {
    name: "common:performer.planSelector.perks.app.label",
    icon: PhoneMobileIcon,
  },
  {
    name: "common:performer.planSelector.perks.contacts.label",
    icon: AudienceIcon,
  },
  {
    name: "common:performer.planSelector.perks.events.label",
    icon: TicketsIcon,
  },
  {
    name: "common:performer.planSelector.perks.faqs.label",
    icon: ClapperBoardIcon,
  },
  {
    name: "common:performer.planSelector.perks.scout.label",
    icon: UserOutlineIcon,
  },
  {
    name: "common:performer.planSelector.perks.advice.label",
    icon: PhoneLandlineIcon,
  },
  {
    name: "common:performer.planSelector.perks.discounts.label",
    icon: StarIcon,
    iconClassName: "text-transparent",
  },
];
