import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import "./PageView.scss";

export interface IPaginationProps {
  /** Page index */
  pageIndex: number;

  /** Boolean property which means is this Page selected or not */
  isSelected?: boolean;

  /** Click event handler which returns Page index */
  handleClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageIndex: number
  ) => void;

  /** KeyDown event handler which returns Page index */
  handleKeyDown?: (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    pageIndex: number
  ) => void;
}

export const PageView: React.FC<IPaginationProps> = ({
  handleClick,
  handleKeyDown,
  pageIndex,
  isSelected,
}) => {
  // @ts-ignore
  const clickHandler = (event) => {
    event.currentTarget.focus();
    if (!isSelected && handleClick) {
      handleClick(event, pageIndex);
    }
  };

  // @ts-ignore
  const keyDownHandler = (event) => {
    if (!isSelected && handleKeyDown) {
      handleKeyDown(event, pageIndex);
    }
  };

  return (
    <div
      onClick={clickHandler}
      onKeyDown={keyDownHandler}
      role="button"
      className={cx("c-page-view", { selected: isSelected })}
      tabIndex={0}
      data-testid={`${dataTestIds.componentLibrary["Molecules.Pagination.pageView"]}${pageIndex}`}
    >
      <span>{pageIndex}</span>
    </div>
  );
};
