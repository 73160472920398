import React, { FC } from "react";
import { Button } from "component-library";
import { useReturnUrl } from "shared-hooks";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { useHistory } from "react-router-dom";
import { useTranslation } from "i18n";
import { authorizationService } from "shared-auth";
import { MemberGroups } from "shared-types";
import { dataTestIds } from "data-testids";
import { HtmlAnchorButton } from "pages/IndexPage/MyAccountPageUserMessaging";
import { IBillingApiResponse } from "services/api/BillingApi/BillingApi";
import checkIsRenewalPeriod from "helpers/checkIsRenewalPeriod";
import checkIsNoActiveSubscription from "helpers/checkIsNoActiveSubscription";
import applicationMap from "services/applicationMap";
import checkIsValidAddress from "helpers/checkIsValidAddress";
import { useSelector } from "react-redux";
import { RoxFlag } from "config/rox";
import { IApplicationState } from "store/index";
import { getRenewalLink } from "helpers/getRenewalLink";
import { useUpdateDetailsPopUp } from "app/hooks/useUpdateDetailsPopUp";
import NotificationBanner, {
  notificationBannerButtonClassNames,
} from "./NotificationBanner";
import { SubscriptionCalculatedStatus } from "app/constants";

type NotificationBannerProps = {
  messageHtml: string;
  buttons: ReadonlyArray<HtmlAnchorButton>;
  performerSubscriptionData: IBillingApiResponse | undefined;
  isLoading: boolean;
};

const SubscriptionNotificationBanner: FC<NotificationBannerProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { redirectWithReturnUrl } = useReturnUrl();
  const isAddOnCheckoutFlagEnabled = useLaunchDarklyFlags(
    FEATURE_FLAGS.AddOnCheckout
  );

  const { address, account, subscription } =
    props.performerSubscriptionData || {};

  const isExpired =
    subscription?.calculatedStatus === SubscriptionCalculatedStatus.Expired;

  const renewalLink = getRenewalLink(isAddOnCheckoutFlagEnabled, {
    planCode: isExpired ? undefined : subscription?.planCode,
  });

  const handleRedirectUser = () => {
    if (updateMyDetailRedirect) {
      redirectWithReturnUrl(
        applicationMap.routes.settingsMyDetails(),
        renewalLink
      );
      return;
    }

    if (checkoutPageRedirect) {
      history.push(renewalLink);
    }
  };

  const { showUpdateDetailsPopUp } = useUpdateDetailsPopUp(handleRedirectUser);

  const isNewAccSettingsFlagOn = useSelector(
    (state: IApplicationState) =>
      state.rox.configuration?.[RoxFlag.NewAccSettings].isEnabled() ?? false
  );

  const isPerformer =
    authorizationService.getUserGroup() === MemberGroups.performer;
  const isRenewalPeriod = checkIsRenewalPeriod({
    planCode: subscription?.planCode,
    calculatedStatus: subscription?.calculatedStatus,
  });
  const noActiveSubscription = checkIsNoActiveSubscription({
    id: account?.id,
    subscription: subscription,
  });

  const isValidAddress = checkIsValidAddress(address);
  const updateMyDetailRedirect =
    !isValidAddress && (noActiveSubscription || isRenewalPeriod);
  const checkoutPageRedirect = isValidAddress && isRenewalPeriod;
  const showPerformerUpgradeButton =
    isPerformer &&
    !isRenewalPeriod && // renewing users should not see upgrade/reactivate button
    (updateMyDetailRedirect || checkoutPageRedirect) &&
    props.buttons.length > 0; // button props come from Umbraco. We should not try to render them if they are not there

  const handleReactivationRenewClick = () => {
    if (!isValidAddress) {
      return showUpdateDetailsPopUp();
    } else {
      return handleRedirectUser();
    }
  };

  if (props.isLoading) return null;

  return (
    <NotificationBanner
      messageHtml={props.messageHtml}
      buttons={props.buttons}
      withButtons={
        (!isRenewalPeriod && !showPerformerUpgradeButton) ||
        !isNewAccSettingsFlagOn
      }
    >
      {showPerformerUpgradeButton && isNewAccSettingsFlagOn ? (
        <>
          <div className={notificationBannerButtonClassNames}>
            <Button
              type={props.buttons[0].type || "primary"}
              href={props.buttons[0].href}
              text={props.buttons[0].text}
            />
          </div>
          <div className={notificationBannerButtonClassNames}>
            <Button
              type="primary"
              text={t("common:button.label.reactivateNow")}
              onClick={handleReactivationRenewClick}
              data-testid={
                dataTestIds.userAccountApp[
                  "MyAccountPage.NotificationBanner.button"
                ]
              }
            />
          </div>
        </>
      ) : null}
      {isRenewalPeriod && isNewAccSettingsFlagOn ? (
        <div className={notificationBannerButtonClassNames}>
          <Button
            type="primary"
            text={t("common:button.label.renew")}
            onClick={handleReactivationRenewClick}
            data-testid={
              dataTestIds.userAccountApp[
                "MyAccountPage.NotificationBanner.button"
              ]
            }
          />
        </div>
      ) : null}
    </NotificationBanner>
  );
};

export default SubscriptionNotificationBanner;
